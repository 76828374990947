.single-post-page {
  header {
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: top center;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 3rem;

    h1 {
      text-shadow: 0 0 25px #000;
    }
  }

  main {
    display: flex;
    justify-content: center;

    > div {
      max-width: 1200px;
    }
  }
}

.full-width-block {
  padding: var(--default-padding);
}
.home {
  #main-header {
    min-height: 500px;
    height: 75vh;
    background: url("images/chris-leipelt-4UgUpo3YdKk-unsplash.jpg")
      rgba(0, 0, 0, 0.65);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center center;
    --default-text-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    color: #fff;

    .inner {
      width: auto;
      padding: var(--default-padding);

      @media (min-width: 1200px) {
        width: 50%;
        padding: 0;
      }
    }

    h2 {
      font-family: var(--font-base);
      font-size: 1.1rem;
      line-height: 1.6;
    }
  }

  #portrait-section {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;

    .inner {
      display: flex;
      flex-flow: column;
      align-items: center;
      color: var(--lighter-text-color);
      position: relative;
      top: -6rem;
      padding: 2rem;
      background: white;

      > div {
        flex: 1;
      }
    }
    @media (min-width: 768px) {
      .nav-anchor {
        top: calc(-90px - 6rem);
      }

      .inner {
        flex-flow: row;
      }
    }
    figure {
      width: 25%;
    }
    ul {
      margin: 0;
      font-size: 1.2rem;
    }
    li {
      margin-top: 0.5rem;
    }
  }

  #services-section {
    display: flex;
    flex-flow: column;
    background-color: var(--light-background-color);
    @media (min-width: 768px) {
      flex-flow: row;
      flex-wrap: wrap;
    }
    article {
      flex: 1;
      min-width: 50%;

      @media (max-width: 767px) {
        &:nth-child(odd) {
          background-color: var(--dark-background-color);
          color: #fff;
        }
      }

      @media (min-width: 768px) {
        &:nth-of-type(3n + 1) {
          background-color: var(--dark-background-color);
          color: #fff;
        }
      }
    }

    .icon {
      color: var(--lighter-text-color);
    }
  }

  #about-me-section {
    position: relative;

    &::after {
      left: 0;
      bottom: 0;
      position: absolute;
      content: "";
      width: 70%;
      height: 0.5rem;
      display: block;
      background: #000;
    }
  }

  #news-section {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        min-width: 25%;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
    article a,
    article a:visited {
      text-decoration: none;
      color: var(--default-text-color);
    }
  }
}

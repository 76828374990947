#page-footer {
  background: var(--dark-background-color);
  color: #fff;

  a,
  a:visited,
  a:active {
    color: var(--primary-color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

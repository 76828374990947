.list-post-page {
  .page-header {
    background: url("images/kaitlyn-baker-vZJdYl5JVXY-unsplash.jpg")
      rgba(0, 0, 0, 0.6);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .post-list {
    margin: 3rem 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: stretch;

    @media (min-width: 440px) {
      flex-flow: row;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  .post-item {
    flex: 1;
    min-width: 15rem;
    padding: 0.5rem;
    display: flex;
    align-items: stretch;

    @media (min-width: 768px) {
      max-width: 20rem;
    }

    > * {
      flex: 1;
      border: 1px solid var(--light-background-color);
      padding: 1rem;
      color: var(--default-text-color);
    }

    h4,
    h5 {
      margin-top: 0;
    }

    > a,
    > a:focus,
    > a:active,
    > a:hover {
      text-decoration: none;
      color: var(--default-text-color);
    }

    .date {
      color: var(--primary-color);
    }
  }
}
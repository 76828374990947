@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700|Roboto&display=swap");

:root {
  --default-text-color: #000;
  --lighter-text-color: #777;
  --primary-color: rgb(227, 44, 119);
  --light-background-color: #f0f0f0;
  --dark-background-color: #303133;
  --font-base: "Roboto", helvetica, arial, sans-serif;
  --font-title: "Playfair Display", serif;
  --default-padding: 1.5rem;
  --default-font-size: 16px;

  @media (min-width: 440px) {
    --default-font-size: 17px;
    --default-padding: 3rem;
  }
  @media (min-width: 768px) { 
    --default-font-size: 19px;
    --default-padding: 5rem;
  }

  @media (min-width: 1200px) {
    --default-font-size: 20px;
    --default-padding: 5rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  color: var(--default-text-color);
  font-family: var(--font-base);
  font-size: var(--default-font-size);
  line-height: 1.65;
}

body {
  margin: 0;
  padding: 1rem;
}

p {
  margin-bottom: 1.15rem;
  text-align: justify;
  @media (min-width: 768px) {
    text-align: left;  
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title);
  font-weight: 700;
  color: currentColor;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

figure {
  margin: 0;
  padding: 0;
}

a, a:visited {
  color: var(--primary-color);
  text-decoration: none;
}

a:focus, a:hover {
  text-decoration: underline;
}

.window-frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1rem solid white;
  pointer-events: none;
  z-index: 10000;
}

.underlined::after {
  content: "";
  display: block;
  width: 100px;
  height: 1px;
  margin: 2rem 0;
  background: var(--primary-color);
}

.inner {
  margin: 3rem auto;
  max-width: 1200px;
}

@media (min-width: 768px) {
  .nav-anchor {
    position: relative;
    top: -90px;
  }
}

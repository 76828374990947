#main-nav {
  position: relative;
  top: 1rem;
  height: 60px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  z-index: 1;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.15);

  @media (min-width: 768px) {
    position: sticky;
    height: 90px;
  }

  .logo,
  .logo img {
    height: 100%;
  }

  .linkedin-icon {
    display: none;
    align-items: center;
    @media (min-width: 768px) {
      display: flex;
    }
  }

  #mobile-nav-toggle {
    border: none;
    background: none;
    padding: 1rem 0 1rem 1rem;
    cursor: pointer;

    @media (min-width: 768px) {
      display: none;
    }
  }

  #nav-items {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(+100%);
    background-color: #fff;

    @media (min-width: 768px) {
      display:flex;
      position: static;
      transform: none;
      background-color: transparent;
    }

    &.visible {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      flex-flow: column;

      @media (min-width: 768px) {
        flex: 1;
        flex-flow: row;
        align-items: center;
      }
    }

    a, a:visited {
      display: block;
      padding: 1rem;
      font-weight: 700;
      text-decoration: none;
      color: var(--default-text-color);
      border-bottom: 1px solid var(--light-background-color);

      &:hover,
      &:active {
        color: #fff;
        background-color: var(--primary-color);
      }

      @media (min-width: 768px) {
        border: none;

        &:hover {
          color: var(--primary-color);
          background-color: transparent;
        }
      }
    }
  }
}
